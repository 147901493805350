$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


    // MAIN SLIDER
        $('.mainSlider').slick({
            fade:true,
            dots: false,
            arrows:false,
            autoplay:true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover:false,
            autoplaySpeed: 5000
        }); 
    // Content SLIDER
        $("#content-slider").mThumbnailScroller({
            axis:"x"
        });

    // Datepicker
        $('#datepicker').datepicker({
            dateFormat: 'dd.mm.yy', // Datumsformat
            regional: 'de', // Sprache
            changeMonth: true, // erlaube Monatswechsel
            changeYear: true, // erlaube Jahreswechsel
        });

    // Fixed navi
        $window = $(window);
        $fixedHeader= $('.page-navi');
        function fixedNavbarHandler() {
            if ($window.scrollTop() > 0) {
                    $fixedHeader.addClass('fixed');
            } else {
                $fixedHeader.removeClass('fixed');
            };
        }
         
        fixedNavbarHandler();
         
        $window.scroll(function() {
            fixedNavbarHandler();
        });
        
    // MAP OVERLAY
        $('.pre-footer .google-maps').click(function(event) {
            $(this).addClass('showMap');
        });
        $(".pre-footer .google-maps").on('mouseleave', function() {
            $(this).removeClass('showMap');
        });

        // console.log(isChecked);
        $("input[name='consult']").on('click', function() {
            var calendarShow = $('#cosultJa').prop('checked');
            if (calendarShow == true) {
               $('#datepicker').addClass('calendarShow');
               $('.consultNein').removeClass('radioNein');
            }else{
               $('#datepicker').removeClass('calendarShow');
               $('#datepicker').datepicker('setDate', null);
               $('.consultNein').addClass('radioNein');
            }
        });
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/